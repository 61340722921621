/*global Component*/
class imgComponent extends Component {

    static  name() {
        return "imgComponent";
    }

    static componentName() {
        return "imgComponent";
    }

    getProps() {
        return {
            'src':String,
            'myclass':String,
            'mystyle':{
                'type':Object,
                'defautl':{}
            },
            "isthumbnails":Boolean,
            "eventclick":Function,
            "imgIndex": Number
        };
    }

    getComputed() {
        return {
            classObjectMap(){
                //console.log("Image Mounted",this);
                let classObject={};
                if(this.isthumbnails)
                    classObject['img-thumbnail']=true;
                return classObject;
            },
            styleObjectMap(){
                let customsStyle = {};
                if(this.isthumbnails) {
                    customsStyle['width'] = "75px";
                    customsStyle['height'] = "75px";
                }
                //console.log('F1',customsStyle,this.mystyle);
                if(this.mystyle)
                    Object.assign(customsStyle,this.mystyle);
                return customsStyle;
            },
        };
    }

    getMethods() {
        return {
            getImageUrl:this.getImageUrl,
            onClickHandler:this.onClickHandler
        };
    }

    getImageUrl(){
        return this.src || "../static/ecommerce/img/nophoto_128x128.jpg";
    }

    onClickHandler(){
        //console.log('Event Handler',this.eventclick,this.imgIndex);
        if(this.eventclick && this.imgIndex)
            this.eventclick(this.imgIndex);
    }

    getTemplate() {
        return `<img class="item-image img-fluid"  :class="classObjectMap" :src="getImageUrl()" alt="Product Image" v-bind:style="styleObjectMap" v-on:click="onClickHandler" />`;
    }
}

imgComponent.registerComponent();